import { core, getMe, getPhoneCountryCodes } from '@shared/modules/api';
import { create as createLog } from '@shared/modules/logger';
import { storage } from 'src/modules/storage';

const log = createLog({ id: 'boot' });
const accessToken = storage.get('accessToken');

core.setVariable('baseUrl', process.env.REACT_APP_API_URL);
if (accessToken) core.setVariable('accessToken', accessToken);

const fetchSession = async () => {
  log('fetchSession', { accessToken })();
  if (!accessToken) return;

  return getMe().catch(() => {
    storage.clear({ inSession: true });
  });
};

log('init');
Promise.all([
  import('./run'),
  fetchSession(),
  getPhoneCountryCodes({ by: 'unscoped' }).catch(() => []),
])
  .then(([{ run }, initialSession = undefined, phoneCodes]) => {
    log(
      'init:success',
      initialSession
        ? { id: initialSession.owner.uuid, kind: initialSession.kind }
        : 'unauthorized'
    )();
    run({ initialSession, phoneCodes });
  })
  .catch((error) => {
    log.error('init:failed', error)();

    import('src/modules/rollbar').then((rollbarImport) =>
      rollbarImport.reportErrorWithCallStack(error)
    );

    // FIXME display error state
  })
  .finally(() => {
    const loader = document.getElementById('loader');
    if (loader) document.body.removeChild(loader);
  });
