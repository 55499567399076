import { createStorage } from 'react-trick-local-storage';

interface Fields {
  accessToken?: string;
  kitchenUuid?: string;
  sessionStarted?: true;
  view?: 'tablet' | 'monitor';
  sound?: true | false;
  packed: Record<string, boolean>;
}

export const storage = createStorage<Fields>({
  key: process.env.REACT_APP_LOCAL_STORAGE_KEY,
});
